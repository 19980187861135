import React, {useState} from 'react';
import {PageContainer} from '../../../components/pageContainer/PageContainer';
import styles from './GuestPayment.module.scss';
import {useTranslation} from 'react-i18next';
import {navilinePaymentTypes} from '../../../constants';
import {PanelRow} from '../../../components/panel/PanelRow';
import {Panel} from '../../../components/panel/Panel';
import {Button} from '../../../components/button/Button';
import {PaymentusTokenizationForm} from '../../../components/paymentusTokenizationForm/PaymentusTokenizationForm';
import {Text} from '../../../components/text/Text';
import {useHistory} from 'react-router-dom';
import {useAuthSelector} from '../../auth/authSlice';
import {UtilityHelpModal} from "../../account/create/AccountCreateAccountInformation";
import {axios} from "../../../services/axiosService";
import moment from "moment";
import {useQuery} from "../../../hooks/useQuery";

const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

enum PaymentStatus {
    NONE = 'NONE',
    LOADING = 'LOADING',
    SUCCESS = 'SUCCESS',
}

export const GuestPayment = () => {
    const history = useHistory();
    const {t} = useTranslation();
    const [apiError] = useState('');
    const [accountError, setAccountError] = useState('');
    const {isAuthenticated} = useAuthSelector(s => s);
    const [paymentAmount, setPaymentAmount] = useState<number>();
    const [paymentAmountEntered, setPaymentAmountEntered] = useState(false);
    const [dueDate, setDueDate] = useState<string>();
    const [paymentStatus, setPaymentStatus] = useState<PaymentStatus>(PaymentStatus.NONE);
    const [utilityHelpModalOpen, setUtilityHelpModalOpen] = useState(false);
    const querystring = useQuery();
    const accountNumber = querystring.get("accountNumber") ?? '';
    const accountType = querystring.get("accountType") ? querystring.get('accountType') : 'UTILITY';
    const amount = querystring.get("amount") ? parseFloat(querystring.get("amount")!) : 0;
    const penaltyAmount = querystring.get("penaltyAmount") ? parseFloat(querystring.get("penaltyAmount")!) : 0;
    const [navilineAccountNumber, setNavilineAccountNumber] = useState(accountNumber);
    const [amountDue, setAmountDue] = useState<number>(amount)
    const [isAccountValid, setIsAccountValid] = useState<boolean>(!!accountNumber && !!accountType);


    const afterSubmit = async () => {
        history.replace('/dashboard');
    }

    const validateAccount = async () => {
        setPaymentStatus(PaymentStatus.LOADING);

        try {
            const response = await axios.get(`/naviline/account?accountNumber=${navilineAccountNumber}`);
            const data = response.data.OutputParms;

            if (data.ErrorCode !== "0000") {
                setAccountError('Could not find account. Please make sure you entered the account information correctly.');
                return;
            }

            const negativeControl = data.AmountDueWithoutPending[data.AmountDueWithoutPending.length - 1] === "-" ? -1.0 : 1.0;
            setAmountDue((parseFloat(data.AmountDueWithoutPending) / 100.0) * negativeControl);
            setDueDate(data.DueDateOfLastBill);
            setPaymentAmount(parseFloat(data.AmountDueWithoutPending) / 100.0);
            setIsAccountValid(true);
        } catch (e) {
            setAccountError('Could not find account. Please make sure you entered the account information correctly.');
        } finally {
            setPaymentStatus(PaymentStatus.NONE);
        }
    }

    const afterPaymentAmountEntered = async () => {
        setPaymentAmountEntered(true);
    }

    return (
        <PageContainer style={isAuthenticated ? {} : {maxWidth: 700, width: '100%'}}>
            <div className="headerContainer">
                <h2>{t('Payment.Payment')}</h2>
            </div>
            {!isAccountValid ?
                <PanelRow>
                    <Panel>
                        <div>
                            {t('Account.CreateCustomerID')} ({t('Account.CreateCustomerIDNote')})
                            <span style={{'color': 'red'}}>*</span>{" "}
                            <span
                                role="button"
                                className={styles.linkText}
                                onClick={() => setUtilityHelpModalOpen(true)}
                            >
                            {t('Account.CreateWhatsThis')}
                        </span>
                            <UtilityHelpModal
                                modalOpen={utilityHelpModalOpen}
                                text={{
                                    header: t('Account.CreateFindYourCustomerId'),
                                    line1: t('Account.CreateToFindYourCustomerId'),
                                    line2: t('Account.CreatePleaseEnterAsItAppears'),
                                }}
                                onClose={() => setUtilityHelpModalOpen(false)}
                            />
                            <Text
                                value={navilineAccountNumber}
                                onChange={(e: any) => setNavilineAccountNumber(e.target.value)}
                                placeholder={t('Account.CreateCustomerID')}
                            />

                            <Button onClick={() => validateAccount()}
                                    loading={paymentStatus === PaymentStatus.LOADING}
                                    disabled={paymentStatus === PaymentStatus.LOADING}
                                    color={'blue'}>
                                {t('Util.Next')}
                            </Button>
                            {(accountError !== '') &&
                                <p className={styles.errorMessage}>{accountError}</p>
                            }
                        </div>
                    </Panel>
                </PanelRow>
                : null
            }

            {
                isAccountValid ?
                    <>
                        <PanelRow>
                            <Panel>
                                <div>
                                    <table>
                                        <tbody>
                                        <tr>
                                            {accountType !== navilinePaymentTypes.businessLicenseRenewal ?
                                                <td><strong>Account Number:</strong></td>
                                                :
                                                <td><strong>License Number:</strong></td>
                                            }
                                            <td>{navilineAccountNumber}</td>
                                        </tr>
                                        {penaltyAmount ?
                                            <>
                                                {/* If the user is applying for permits, check if there is a penalty amount */}
                                                <tr>
                                                    <td><strong>{t('Payment.PermitDue')}:</strong></td>
                                                    <td>{penaltyAmount ? formatter.format(amountDue!) :  ''}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{color: '#BF0000'}}><strong>{t('Payment.PenaltyDue')}:</strong></td>
                                                    <td style={{color: '#BF0000'}}>{penaltyAmount ? formatter.format(penaltyAmount!) :  ''}</td>
                                                </tr>
                                                <tr>
                                                    <td><strong>{t('Payment.TotalDue')}:</strong></td>
                                                    <td>{penaltyAmount ? formatter.format(amountDue + penaltyAmount!) :  ''}</td>
                                                </tr>
                                            </>
                                            :
                                            <tr>
                                                <td><strong>{t('Payment.TotalDue')}:</strong></td>
                                                <td>{amountDue ? formatter.format(amountDue!) :  ''}</td>
                                            </tr>
                                        }
                                        
                                        {dueDate ?
                                            <tr>
                                                <td><strong>Due Date:</strong></td>
                                                <td>{moment(dueDate, 'MMDDYYYY').format('MM/DD/YYYY')}</td>
                                            </tr>
                                            : null
                                        }
                                        </tbody>
                                    </table>
                                </div>
                            </Panel>
                        </PanelRow>
                        <PanelRow>
                            <Panel>
                                <div>
                                    <p><strong>Enter your payment amount:</strong></p>

                                    <div>
                                        Payment: $<Text
                                        value={paymentAmount ?? 0}
                                        fluid={false}
                                        step="0.01"
                                        min={1}
                                        type={'number'}
                                        disabled={paymentAmountEntered}
                                        onChange={(event: any, data: any) => setPaymentAmount(parseFloat(data.value))}/>
                                    </div>

                                    <Button onClick={() => afterPaymentAmountEntered()}
                                            disabled={paymentAmountEntered}
                                            color={'blue'}>
                                        {t('Util.Next')}
                                    </Button>

                                </div>
                            </Panel>
                        </PanelRow>
                    </>
                    : null
            }

            {isAccountValid && paymentAmountEntered ?
                <>
                    <PanelRow>
                        <Panel>
                            <PaymentusTokenizationForm
                                transactionType={'SINGLE'}
                                afterToken={afterSubmit}
                                fullToken={false}
                                amount={paymentAmount?.toFixed(2)}
                                accountNumber={navilineAccountNumber}
                                paymentType={accountType as unknown as navilinePaymentTypes}/>
                            {(apiError !== '') &&
                                <p className={styles.errorMessage}>{apiError}</p>
                            }
                        </Panel>
                    </PanelRow>
                </>
                : null}

        </PageContainer>
    )
}
