import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Button, Icon, Modal } from 'semantic-ui-react';
import styles from './AutopayConfigModal/AutopayConfigModal.module.scss';
import { setDeleteModalOpen, useAppStateBillingSettingsSelector } from './billingSettingsSlice';
import { removePaymentInformation } from './billingSettingsActionCreators';

interface DeleteModalProps {
    modalOpen: boolean;
    onClose(): void;
}

export const DeleteModal = ({modalOpen, onClose}: DeleteModalProps) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { autopayCustomerId, autopayLocationId } = useAppStateBillingSettingsSelector(s => s);

    const submit = () => {
        dispatch(removePaymentInformation(autopayCustomerId, autopayLocationId));
        dispatch(setDeleteModalOpen(false));
    }

    return (
        <Modal open={modalOpen} onClose={() => onClose()} size="mini">
            <Modal.Header>
                {t('Account.RemoveUtilityAccount')}
                <div style={{'float': 'right'}}>
                    <Icon
                        icon='x'
                        color='black'
                        size="large"
                        onClick={() => onClose()}
                    />
                </div>
            </Modal.Header>
            <Modal.Content>
                <div className={styles.modalContent}>
                    <form onSubmit={submit}>
                        <span>
                            <p>{t('Account.RemoveUtilityAccountDesc')}</p>
                        </span>
                        <div className={styles.submitContainer}>
                            <div className={styles.buttonRight}>
                                <Button
                                    onClick={submit}
                                    color="green"
                                    fluid={false}
                                >
                                    {t('Util.OK')}
                                </Button>
                            </div>
                            <div className={styles.buttonLeft}>
                                <Button
                                    onClick={onClose}
                                    color="blue"
                                    fluid={false}
                                >
                                    {t('Util.No')}
                                </Button>
                            </div>
                        </div>
                    </form>
                </div>
            </Modal.Content>
        </Modal>
    );
}