import React from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { Counter } from '../features/counter/Counter';
import { ForgotPassword } from '../features/forgotPassword/ForgotPassword';
import { ResetPassword } from '../features/resetPassword/ResetPassword';
import { Dashboard } from '../features/dashboard/Dashboard';
import { ActivitiesList } from '../features/activities/list/ActivitiesList';
import { ActivitiesCreate } from '../features/activities/create/ActivitiesCreate';
import { LaserficheEmbed } from '../features/laserfiche/embed/LaserficheEmbed';
import { ActivitiesEdit } from '../features/activities/edit/ActivitiesEdit';
import { LanguagesList } from '../features/languages/list/LanguagesList';
import { LanguagesEdit } from '../features/languages/edit/LanguagesEdit';
import { Home } from '../features/home/Home';
import { AccountCreateContainer } from '../features/account/create/AccountCreateContainer';
import { AccountEdit } from '../features/account/edit/AccountEdit';
import { Login } from '../features/login/Login';
import { Signup } from '../features/signup/Signup';
import { PermitForm } from '../features/customForms/permitForm/PermitForm';
import { ZoningInquiry } from '../features/customForms/zoningInquiry/ZoningInquiry';
import { Report } from '../features/customForms/report/Report';
import { Messages } from '../features/messages/Messages';
import { Payment } from '../features/payment/Payment';
import { OrganizationManagement } from '../features/organizationManagement/OrganizationManagement';
import { AddOrganization } from '../features/organizationManagement/add/AddOrganization';
import { personalOrganizationKey } from '../constants';
import { authService } from '../services/authService';
import { VerificationRequired } from '../features/account/verification/VerificationRequired';
import { ElginToday } from '../features/elginToday/ElginToday';
import { ActivitiesInProgress } from '../features/activities/view/lists/ActivitiesViewInProgress';
import { ActivitiesCompleted } from '../features/activities/view/lists/ActivitiesViewCompleted';
import { ActivitiesViewItem } from '../features/activities/view/item/ActivitiesViewItem';
import { Search } from '../features/search/Search';
import { ActivityStart } from '../components/activityStart/ActivityStart';
import { SalesforcePublicFieldsList } from '../features/salesforcePublicFields/SalesforcePublicFields';
import { BusinessApplicationScreener } from '../features/customForms/BusinessApplicationScreener/BusinessApplicationScreener';
import { OpenMobileApp } from '../components/openMobileApp/OpenMobileApp';
import { KnowledgeBase } from '../features/knowledgeBase/KnowledgeBase';
import { KnowledgeBaseDetails } from '../features/knowledgeBase/Details/KnowledgeDetails';
import { UserEdit } from '../features/users/edit/UserEdit';
import { SAML } from '../features/sso/SAML';
import { BusinessLicenseRenewal } from '../features/businessLicense/Renewal';
import { PermitSearch } from '../features/permitSearch/PermitSearch';
import { GarbageSearch } from '../features/garbageSearch/GarbageSearch';
import { GuestPayment } from '../features/payment/GuestPayment/GuestPayment';
import { SystemAlerts } from '../features/systemAlerts/SystemAlerts';
import { PaymentLPI } from '../features/payment/PaymentLPI';
import { AllServices } from '../components/popularServices/AllServices';
import { WaterBillPaymentHistoryRecords } from '../features/payment/WaterBillPaymentHistoryRecords/WaterBillPaymentHistoryRecords';
import { PaymentHistory } from '../features/payment/WaterBillPaymentHistoryRecords/PaymentHistory';
import { BoardsAndCommissions } from '../features/boardsAndCommissions/BoardsAndCommissions';
import { BoardOrCommissionView } from '../features/boardsAndCommissions/view/BoardOrCommissionView';
import { UsersListV2 } from '../features/userAdminView/list/UsersListV2';
import { useFeatureFlag } from '../features/featureFlags/FeatureFlag';
import { UsersList } from '../features/users/list/UsersList';
import { UserEditV2 } from '../features/userAdminView/edit/UserEditV2';
import { UserViewV2 } from '../features/userAdminView/view/UserViewV2';
import { BulkPushNotificationDashboard } from '../features/bulkPushNotifications/BulkPushNotificationsDashboard';

export interface RoutesProps {
    isAuthenticated: boolean;
    isVerified: boolean;
    hasSalesforceId?: boolean;
    selectedOrganization: string;
}

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};

const Blank = () => {
    return <p></p>;
};

export const Routes: React.SFC<RoutesProps> = (props: RoutesProps) => {
    const urlParams = useQuery();
    const userAdminMenuV2FF = useFeatureFlag('EnableUserAdminMenuV2');

    if (urlParams.has('oobCode')) {
        authService
            .applyActionCode(urlParams.get('oobCode') as string)
            .then(async (_) => {
                await authService.completeEmailVerification();
            })
            .catch((err) => {
                console.error(err);
            });
    }

    if (props.hasSalesforceId && props.isVerified) {
        const organizationManagement =
            props.selectedOrganization !== personalOrganizationKey ? ( // Only show to organization users
                <Route
                    exact
                    path="/organization_management"
                    component={OrganizationManagement}
                />
            ) : null;
        const report =
            props.selectedOrganization === personalOrganizationKey ? ( // Only show to individual users
                <Route exact path="/report" component={Report} />
            ) : null;
        return (
            <Switch>
                <Route exact path="/" component={Dashboard} />
                <Route exact path="/activities" component={ActivitiesList} />
                <Route exact path="/languages" component={LanguagesList} />
                <Route exact path="/counter" component={Counter} />
                <Route exact path="/dashboard" component={Dashboard} />
                <Route
                    exact
                    path="/activities/create"
                    component={ActivitiesCreate}
                />
                <Route
                    exact
                    path="/laserfiche/form"
                    component={LaserficheEmbed}
                />
                <Route
                    exact
                    path="/activities/other_permit"
                    component={PermitForm}
                />
                <Route
                    exact
                    path="/activities/business_application"
                    component={BusinessApplicationScreener}
                />
                <Route
                    exact
                    path="/activities/zoning_inquiry"
                    component={ZoningInquiry}
                />
                <Route
                    exact
                    path="/activities/:id"
                    component={ActivitiesEdit}
                />
                <Route
                    exact
                    path="/languages/:language"
                    component={LanguagesEdit}
                />
                <Route
                    exact
                    path="/users"
                    component={
                        userAdminMenuV2FF
                            ? userAdminMenuV2FF.enabled
                                ? UsersListV2
                                : UsersList
                            : Blank
                    }
                />
                <Route exact path="/users/:id" component={UserEdit} />
                <Route
                    exact
                    path="/users/edit/:id/:prev_page?"
                    component={UserEditV2}
                />
                <Route
                    exact
                    path="/users/view/:id/:prev_page?"
                    component={UserViewV2}
                />
                {report}
                <Route exact path="/messages" component={Messages} />
                <Route
                    path="/account/create"
                    component={AccountCreateContainer}
                />
                <Route exact path="/payment" component={Payment} />
                <Route exact path="/paymentLPI" component={PaymentLPI} />
                <Route
                    exact
                    path="/payment/water-bill-payment-records"
                    component={WaterBillPaymentHistoryRecords}
                />
                <Route
                    exact
                    path="/payment/payment-history"
                    component={PaymentHistory}
                />
                {organizationManagement}
                <Route
                    exact
                    path="/add_organization"
                    component={AddOrganization}
                />
                <Route exact path="/elgin_today" component={ElginToday} />
                <Route path="/account/:id" component={AccountEdit} />
                <Route
                    exact
                    path="/in_progress"
                    component={ActivitiesInProgress}
                />
                <Route
                    exact
                    path="/completed_activities"
                    component={ActivitiesCompleted}
                />
                <Route
                    path="/activity-view/:type/:id"
                    component={ActivitiesViewItem}
                />
                <Route path="/search" component={Search} />
                <Route path="/activity/start" component={ActivityStart} />
                <Route
                    exact
                    path="/saleforce_public_fields"
                    component={SalesforcePublicFieldsList}
                />
                <Route exact path="/openMobileApp" component={OpenMobileApp} />
                <Route exact path="/knowledgebase" component={KnowledgeBase} />
                <Route
                    exact
                    path="/knowledgebase/:id"
                    component={KnowledgeBaseDetails}
                />
                <Route exact path="/sso/saml" component={SAML} />
                <Route
                    exact
                    path="/businesslicense"
                    component={BusinessLicenseRenewal}
                />
                <Route exact path="/permit-search" component={PermitSearch} />
                <Route exact path="/garbage-search" component={GarbageSearch} />
                <Route exact path="/feedback" component={Report} />
                <Route exact path="/system_alerts" component={SystemAlerts} />
                <Route
                    exact
                    path="/bulk_push_notifications"
                    component={BulkPushNotificationDashboard}
                />
                <Route
                    exact
                    path="/view-all-services"
                    component={AllServices}
                />
                <Route
                    exact
                    path="/boards-and-commissions"
                    component={BoardsAndCommissions}
                />
                <Route
                    exact
                    path="/boards-and-commissions/:id"
                    component={BoardOrCommissionView}
                />
                <Route exact path="/:RecordId?" component={Dashboard} />
                <Redirect to="/" />
            </Switch>
        );
    } else if (props.isAuthenticated && props.isVerified) {
        return (
            <Switch>
                <Route exact path="/" component={Home} />
                <Route path="/search" component={Search} />
                <Route exact path="/knowledgebase" component={KnowledgeBase} />
                <Route
                    exact
                    path="/knowledgebase/:id"
                    component={KnowledgeBaseDetails}
                />
                <Route
                    path="/account/create"
                    component={AccountCreateContainer}
                />
                <Route exact path="/openMobileApp" component={OpenMobileApp} />
                <Redirect to="/account/create" />
                <Route exact path="/elgin_today" component={ElginToday} />
            </Switch>
        );
    } else if (props.isAuthenticated && !props.isVerified) {
        return (
            <Switch>
                <Route exact path="/" component={Home} />
                <Route path="/search" component={Search} />
                <Route exact path="/knowledgebase" component={KnowledgeBase} />
                <Route
                    exact
                    path="/knowledgebase/:id"
                    component={KnowledgeBaseDetails}
                />
                <Route
                    exact
                    path="/account/verificationRequired"
                    component={VerificationRequired}
                />
                <Route exact path="/openMobileApp" component={OpenMobileApp} />
                <Redirect to="/account/verificationRequired" />
                <Route exact path="/elgin_today" component={ElginToday} />
            </Switch>
        );
    } else {
        return (
            <Switch>
                <Route exact path="/" component={Home} />
                <Route exact path="/login" component={Login} />
                <Route exact path="/signup" component={Signup} />
                <Route exact path="/resetPassword" component={ResetPassword} />
                <Route
                    exact
                    path="/forgotpassword"
                    component={ForgotPassword}
                />
                <Route path="/search" component={Search} />
                <Route exact path="/activity/start" component={ActivityStart} />
                <Route exact path="/openMobileApp" component={OpenMobileApp} />
                <Route exact path="/knowledgebase" component={KnowledgeBase} />
                <Route
                    exact
                    path="/knowledgebase/:id"
                    component={KnowledgeBaseDetails}
                />
                <Route exact path="/sso/saml" component={SAML} />
                <Route exact path="/payment" component={GuestPayment} />
                <Route
                    exact
                    path="/businesslicense"
                    component={BusinessLicenseRenewal}
                />
                <Route exact path="/permit-search" component={PermitSearch} />
                <Route
                    exact
                    path="/view-all-services"
                    component={AllServices}
                />
                <Route exact path="/elgin_today" component={ElginToday} />
                <Route exact path="/garbage-search" component={GarbageSearch} />
                <Route
                    exact
                    path="/boards-and-commissions"
                    component={BoardsAndCommissions}
                />
                <Route
                    exact
                    path="/boards-and-commissions/:id"
                    component={BoardOrCommissionView}
                />
                <Redirect to="/" />
            </Switch>
        );
    }
};
