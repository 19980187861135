import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Modal } from 'semantic-ui-react';
import { Button } from '../../components/button/Button';
import { DataList } from '../../components/dataList/DataList';
import { Dropdown, DropdownOptions } from '../../components/dropdown/Dropdown';
import { Icon } from '../../components/icon/Icon';
import { Panel } from '../../components/panel/Panel';
import { PanelRow } from '../../components/panel/PanelRow';
import { Spinner } from '../../components/spinner/Spinner';
import { SalesforceFieldCheckboxes } from './SalesforceFieldCheckboxes';
import { getSObjectFieldOptions, getSObjectPublicFields, getSObjectsWithPublicFields, saveSObjectPublicFields } from './salesforcePublicFieldsActionCreators';
import { salesforcePublicFieldsListActions as sfFieldActions, useSalesforcePublicFieldsSelector } from './salesforcePublicFieldsSlice';

interface ModalProps {
    modalOpen: boolean;
    onClose(value?: string): void;
}

const AddModal = ({modalOpen, onClose}: ModalProps) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const sObject = useSalesforcePublicFieldsSelector(s => s.selectedSObject);

    // todo: get from server
    let salesforceObjects: DropdownOptions[] = [
        {
            key: "Case",
            text: "Case",
            value: "Case"
        },
        {
            key: "COE_Permit__c",
            text: "COE Permit",
            value: "COE_Permit__c"
        },
        {
            key: "Permit_Applications__c",
            text: "Permit Applications",
            value: "Permit_Applications__c"
        },
        {
            key: "Zoning_Inquiry__c",
            text: "Zoning Inquiry",
            value: "Zoning_Inquiry__c"
        },
        {
            key: "LPI_Application__c",
            text: "LPI Application",
            value: "LPI_Application__c"
        },
    ];
    
    return (
        <Modal open={modalOpen} onClose={() => onClose()} size="small">
            <Modal.Header>
                {t('SFPublicFields.SelectSObject')}
                <div style={{'float': 'right'}}>
                    <Icon
                        icon='x'
                        color='black'
                        size="large"
                        onClick={() => onClose()}
                    />
                </div>
            </Modal.Header>
            <Modal.Content>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: '200px',
                        justifyContent: 'space-between',
                    }}
                >
                    <Dropdown
                        search
                        value={sObject}
                        options={salesforceObjects}
                        placeholder={`${t('Activity.SalesforceObject')}...`}
                        onChange={(e: any, data: any) => dispatch(sfFieldActions.setSelectedSObject(data.value))}
                    />
                    <div>
                        <Button onClick={() => onClose(sObject)}>
                            {t('Util.Submit')}
                        </Button>
                    </div>
                </div>
            </Modal.Content>
        </Modal>
    );
}

const EditModal = ({modalOpen, onClose}: ModalProps) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { fieldsLoading, salesforceObjectFields, salesforceObjectFieldOptions } = useSalesforcePublicFieldsSelector(s => s);
    
    return (
        <Modal open={modalOpen} onClose={() => onClose()} size="small" >
            <Modal.Header>
                {t('SFPublicFields.SelectSObjectFields')}
                <div style={{'float': 'right'}}>
                    <Icon
                        icon='x'
                        color='black'
                        size="large"
                        onClick={() => onClose()}
                    />
                </div>
            </Modal.Header>
            <Modal.Content>
                <div style={{height: '250px'}}>
                    {fieldsLoading ? 
                        <div style={{backgroundColor: 'dark-gray'}}>
                            <Spinner isLoading={fieldsLoading} />
                        </div>
                    :
                        <>
                            <SalesforceFieldCheckboxes
                                value={salesforceObjectFields}
                                options={salesforceObjectFieldOptions}
                                onChange={(data: string[]) => dispatch(sfFieldActions.setSalesforceObjectFields(data))}
                            />
                            <div>
                                <Button onClick={() => onClose("save")}>
                                    {t('Util.Submit')}
                                </Button>
                            </div>
                        </>
                    }
                </div>
            </Modal.Content>
        </Modal>
    );
}

export const SalesforcePublicFieldsList = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { loading, addModalOpen, editModalOpen, data } = useSalesforcePublicFieldsSelector(s => s);

    useEffect(() => {
        dispatch(getSObjectsWithPublicFields());
    }, [dispatch]);

    const addModalCallback = useCallback((value?: string) => {
        dispatch(sfFieldActions.setAddModalOpen(false));
        if (value) {
            console.log(`Closed Add modal with value: ${value}`);
            dispatch(sfFieldActions.setEditModalOpen(true));
            dispatch(getSObjectFieldOptions(value));
            dispatch(getSObjectPublicFields());
        } else {
            console.log(`Closed Add modal without value`);
        }
    }, [dispatch]);

    const editModalCallback = useCallback((value?: string) => {
        dispatch(sfFieldActions.setEditModalOpen(false));
        if (value) {
            console.log(`Closed Edit modal with value: ${value}`);
            if (value === 'save') {
                dispatch(saveSObjectPublicFields());
            }
        } else {
            console.log(`Closed Edit modal without value`);
        }
    }, [dispatch]);

    const handleAddOpen = useCallback(() => {
        dispatch(sfFieldActions.setSelectedSObject(""));
        dispatch(sfFieldActions.setAddModalOpen(true));
    }, [dispatch]);

    const handleEditOpen = useCallback((value: any) => {
        dispatch(sfFieldActions.setSelectedSObject(value));
        dispatch(getSObjectFieldOptions(value));
        dispatch(getSObjectPublicFields());
        dispatch(sfFieldActions.setEditModalOpen(true));
    }, [dispatch]);

    return (
        <PanelRow>
            <Panel>
                <AddModal modalOpen={addModalOpen} onClose={addModalCallback} />
                <EditModal modalOpen={editModalOpen} onClose={editModalCallback} />
                <DataList
                    title={t('SFPublicFields.SalesforcePublicFields')}
                    items={data}
                    addCallback={handleAddOpen}
                    editCallback={handleEditOpen}
                />
                <Spinner isLoading={loading} />
            </Panel>
        </PanelRow>
    );
}
