import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useAppStateLoginSelector, setEmail, setPassword, setLoginError, clearLogin} from './loginSlice'
import styles from './Login.module.scss';
import {Input, Message} from 'semantic-ui-react';
import {Link, useHistory, useLocation} from 'react-router-dom';
import {Reasoning, ErrorValidationLabel} from '../../components/errorValidationLabel/ErrorValidationLabel';
import {login, signInWithGoogle, signInWithFacebook, signInWithApple} from './loginActionCreators';
import {Button} from '../../components/button/Button';
import {useTranslation} from 'react-i18next';
import {Spinner} from '../../components/spinner/Spinner';
import {useAppStateActivitiesWizardSelector} from '../../components/activitiesWizard/activitiesWizardSlice';
import {getActivity} from '../../components/activitiesWizard/activitiesWizardActionCreators';
import { navigateToActivity } from '../../services/activityService';

export function Login() {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const {t} = useTranslation();

    const searchParams = new URLSearchParams(location.search);

    const {email, password, loginError, loading} = useAppStateLoginSelector(state => state);
    const [invalidEmail, setInvalidEmail] = useState<Reasoning | null>(null);
    const [invalidPassword, setInvalidPassword] = useState<Reasoning | null>(null);
    const activity = useAppStateActivitiesWizardSelector(state => state.selectedItem);
    const activityRedirect = searchParams.has('activityId');
    const guestEnabled = searchParams.get('guest_enabled');
    const redirectTo = searchParams.get('redirectto');

    useEffect(() => {
        // clear inputs / errors on first load
        dispatch(clearLogin(null));
        dispatch(setLoginError(null));
    }, [dispatch])

    if (activityRedirect) {
        const id = searchParams.get('activityId') as string;
        localStorage.setItem('activityRedirectId', id);
        if (!activity || !activity.Id) {
            dispatch(getActivity(id));
        }
    }

    const handleSignin = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        let valid = validateForm();
        if (valid) {
            if (activityRedirect) {
                dispatch(login(email, password, navToActivity));
            } else {
                dispatch(login(email, password, navigateToDashboard));
            }
        }
    }

    const handleApple = async () => {
        dispatch(signInWithApple());
    }

    const handleFacebook = async () => {
        dispatch(signInWithFacebook());
    }

    const handleGoogle = async () => {
        dispatch(signInWithGoogle());
    }

    const navigateToDashboard = () => {
        history.push('/dashboard');
    }

    const navToActivity = () => {
        if (activity) {
            const {historyPushLocation, dispatchFunction} = navigateToActivity(activity);

            if (dispatchFunction) dispatch(dispatchFunction);
            if (historyPushLocation) history.push(historyPushLocation);
        } else {
            history.push('/dashboard');
        }
    }

    const validateForm = () => {
        clearValidators();
        let validForm = true;

        if (email.length === 0) {
            setInvalidEmail('Required');
            validForm = false;
        }

        if (password.length === 0) {
            setInvalidPassword('Required');
            validForm = false;
        }

        return validForm;
    }

    const clearValidators = () => {
        setInvalidEmail(null);
        setInvalidPassword(null);
    }

    return (
        <div className={guestEnabled && redirectTo ? styles.fullWidthPage : styles.page}>
            <Spinner fullPage isLoading={loading}/>
            <div className={guestEnabled && redirectTo ? styles.hozForm : styles.form}>
                {guestEnabled && redirectTo ?
                    <>
                        <div className={styles.row} style={{marginTop: 40}}>
                            <div>
                                <h2>{t('Login.ContinueAsGuest')}</h2>
                                <div className={styles.row}>
                                    <Button color="grey" onClick={() => history.push(redirectTo)}>
                                        {redirectTo === 'payment' ?
                                            <>{t('Login.MakePaymentAsGuest')}</>
                                            :
                                            <>{t('Login.ContinueAsGuest')}</>
                                        }
                                    </Button>
                                    <span className={styles.delayedAccountText}>
                                        {t('Login.CreateAccountLater')}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className={styles.orWrapper}>
                            <div className={styles.orLine}></div>
                            <div className={styles.orWordWrapper}>
                                <div className={styles.orWord}>or</div>
                            </div>
                        </div>
                    </>
                    : null}
                {loginError ?
                    <div className={styles.row}>
                        <Message negative>
                            <Message.Header>{t(loginError)}</Message.Header>
                        </Message>
                    </div>
                    : null}
                <div>
                    <div className={styles.row}>
                        <h2>{t('Login.Login')}</h2>
                        {guestEnabled ?
                            <strong>{t('Login.GuestHeader')}</strong>
                        : null}
                    </div>
                    <form onSubmit={handleSignin}>
                        <div className={styles.row}>
                            {t('Login.EmailAddress')}
                            <Input
                                value={email}
                                onChange={(e: any) => dispatch(setEmail({value: e.target.value}))}
                                type="email"
                            />
                            {invalidEmail ? <ErrorValidationLabel fieldDescription={'Email'}/> : null}
                        </div>

                        <div className={styles.row}>
                            {t('Login.Password')}
                            <Input
                                value={password}
                                onChange={(e: any) => dispatch(setPassword({value: e.target.value}))}
                                type="password"
                            />
                            {invalidPassword ? <ErrorValidationLabel fieldDescription={'Password'}/> : null}
                        </div>
                        <div className={styles.row} style={{textAlign: 'center'}}>
                            <Link to="/forgotpassword">{t('Login.ForgotPassword')}</Link>
                        </div>
                        <div className={styles.row}>
                            <Button color="blue" type="submit" data-testid="loginButton">
                                {t('Login.Login')}
                            </Button>
                        </div>
                    </form>
                    <div className={styles.row}>
                        <Link to="/signup" className={styles.signup}
                              data-testid="createAccountButton">{t('Login.CreateNewAccount')}</Link>
                    </div>
                    <div className={styles.row}>
                        <hr/>
                    </div>
                    <div className={styles.row}>
                        <Button color='black' onClick={handleApple} style={styles.buttonWithIcon}>
                            <i className={`fab fa-apple ${styles.buttonIcon}`}></i>
                            <span>{t('Login.SignInWithApple')}</span>
                        </Button>
                    </div>
                    <div className={styles.row}>
                        <Button color='darkBlue' onClick={handleFacebook} style={styles.buttonWithIcon}>
                            <i className={`fab fa-facebook-f ${styles.buttonIcon}`}></i>
                            <span>{t('Login.SignInWithFacebook')}</span>
                        </Button>
                    </div>
                    <div className={styles.row}>
                        <Button color='lightBlue' onClick={handleGoogle} style={styles.buttonWithIcon}>
                            <i className={`fab fa-google ${styles.buttonIcon}`}></i>
                            <span>{t('Login.SignInWithGoogle')}</span>
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
}
