import React, { useEffect, useState } from 'react';
import styles from './BillingSettings.module.scss';
import { Modal } from 'semantic-ui-react';
import { DataList } from '../../../components/dataList/DataList';
import { Icon } from '../../../components/icon/Icon';
import { setAuthNetModalOpen, setAutopayAccount, setAutopayCustomerId, setAutopayLocationId, setAutopayModalOpen, setDeleteModalOpen, setNavilineAccountNumber, setNavilineModalOpen, setOrganizationId, setStreetNumber, setUserId, setUtilityHelpModal, useAppStateBillingSettingsSelector } from './billingSettingsSlice';
import { useDispatch } from 'react-redux';
import { Text } from '../../../components/text/Text';
import { useTranslation } from 'react-i18next';
import { UtilityHelpModal } from '../create/AccountCreateAccountInformation';
import { Button } from '../../../components/button/Button';
import { addPaymentInformation, loadPaymentInformation, loadPaymentProfile, removePaymentMethod } from './billingSettingsActionCreators';
import { AddCardModal } from './AddCardModal';
import { AutopayConfigModal } from './AutopayConfigModal/AutopayConfigModal';
import { DeleteModal } from './DeleteModal';
import { openMessageModal } from '../../../components/statusModal/messageModalActionCreators';
import { MessageModal } from '../../../components/statusModal/MessageModal';
import { axios } from '../../../services/axiosService';

export interface BillingSettingsProps {
    UserId?: string;
    OrganizationId?: string;
};
interface NavilineAddModalProps {
    modalOpen: boolean;
    onClose(accountNumber?: string, streetNumber?: string): void;
}

const NavilineAddModal = ({modalOpen, onClose}: NavilineAddModalProps) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { navilineAccountNumber, streetNumber, utilityHelpModalOpen } = useAppStateBillingSettingsSelector(state => state);

    const submit = () => {
        onClose(navilineAccountNumber, streetNumber);
    }

    return (
        <Modal open={modalOpen} onClose={() => onClose()} size="mini">
            <Modal.Header>
                {t('Billing.AddWaterBillingAccountInformation')}
                <div style={{'float': 'right'}}>
                    <Icon
                        icon='x'
                        color='black'
                        size="large"
                        onClick={() => onClose()}
                    />
                </div>
            </Modal.Header>
            <Modal.Content>
                <div className={styles.modalContent}>
                    <form onSubmit={submit}>
                        {t('Account.CreateCustomerID')} ({t('Account.CreateCustomerIDNote')})
                        <span style={{ 'color': 'red' }}>*</span>{" "}
                        <span
                            role="button"
                            className={styles.linkText}
                            onClick={() => dispatch(setUtilityHelpModal(true))}
                        >
                            {t('Account.CreateWhatsThis')}
                        </span>
                        <UtilityHelpModal
                            modalOpen={utilityHelpModalOpen}
                            text={{
                                header: t('Account.CreateFindYourCustomerId'),
                                line1: t('Account.CreateToFindYourCustomerId'),
                                line2: t('Account.CreatePleaseEnterAsItAppears'),
                            }}
                            onClose={() => dispatch(setUtilityHelpModal(false))}
                        />
                        <Text
                            value={navilineAccountNumber}
                            onChange={(e: any) => {
                                // Remove any spaces and leading 0's that users enter
                                const enteredNavilineAccountNumber = e.target.value.replace(/^0+/, '').replace(/-0+/, '-').replace(/ /g, '');
                                dispatch(setNavilineAccountNumber(enteredNavilineAccountNumber));
                            }}
                            placeholder={t('Account.CreateCustomerID')}
                        />
                        {t('Account.StreetNumberOfServiceAddress')}
                        <Text
                            value={streetNumber}
                            onChange={(e: any) => {
                                const enteredStreetNumber = e.target.value.replace(/ /g, '');
                                dispatch(setStreetNumber(enteredStreetNumber));
                            }}
                            placeholder={t('Account.ServiceAddressStreetNumber')}
                            style={{marginBottom: 0}}
                        />
                        <span>
                            {t('Account.NoteStreetNumberOnly')}
                        </span>
                        <div className={styles.submitContainer}>
                            <div>
                                <Button
                                    onClick={submit}
                                    color="blue"
                                    fluid={false}
                                    disabled={!navilineAccountNumber || !streetNumber}
                                >
                                    {t('Util.Submit')}
                                </Button>
                            </div>
                        </div>
                    </form>
                </div>
            </Modal.Content>
        </Modal>
    );
};

export function BillingSettings({UserId, OrganizationId}: BillingSettingsProps) {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [autopayAcctEnabled, setAutopayAcctEnabled] = useState(false);
    const [autopayAcctCard, setAutopayAcctCard] = useState();
    const [autopayId, setAutopayId] = useState();
    const [deleteCardWarningOpen, setDeleteCardWarningOpen] = useState(false);
    const [deletedCardId, setDeletedCardId] = useState('');

    useEffect(() => {
        dispatch(setUserId(UserId));
        dispatch(setOrganizationId(OrganizationId));
        dispatch(loadPaymentInformation());
        dispatch(loadPaymentProfile());
    }, [dispatch, UserId, OrganizationId]);

    const { navilineModalOpen, navilineListData, authNetModalOpen, paymentProfile, autopayModalOpen, deleteModalOpen } = useAppStateBillingSettingsSelector(state => state);
    const paymentMethods = paymentProfile ? paymentProfile
        .map((profile: any) => ({
            Id: profile.Id,
            Label: `${profile.Type} - ${profile.MaskedAccountNumber}${profile.ProfileDescription ? ` (${profile.ProfileDescription})` : ""}`,
            Data: profile,
            Tag: profile.Default ? 'Default' : undefined
        }))
        : [];

    const removeCard = async (id: string) => {
        // Show warning if card is attached to autopay
        const result = (await axios.get(`/payment-methods/${id}`)).data;
        if (result) {
            setDeletedCardId(id);
            setDeleteCardWarningOpen(true);
            dispatch(openMessageModal('warning-delete-saved-card', 'warning'));
        }
        else {
            dispatch(removePaymentMethod(id));
        }
    }

    return (
        <div>
            <h1>{t('Billing.BillingSettings')}</h1>
            <p className={styles.achDisclaimer}>{t('Billing.BillingSettingsDesc')}</p>
            <h1>{t('Billing.ManageCreditCardAutopay')}</h1>
            <NavilineAddModal modalOpen={navilineModalOpen} onClose={(accountNumber, streetNumber) => accountNumber && streetNumber ? dispatch(addPaymentInformation(accountNumber, streetNumber, navilineListData)) : dispatch(setNavilineModalOpen(false))} />
            <AddCardModal modalOpen={authNetModalOpen} />
            <AutopayConfigModal modalOpen={autopayModalOpen} enabled={autopayAcctEnabled} savedCard={autopayAcctCard} id={autopayId} />
            <DeleteModal modalOpen={deleteModalOpen} onClose={() => dispatch(setDeleteModalOpen(false))} />
            <MessageModal modalOpen={deleteCardWarningOpen} 
                onConfirmationButtonClick={() => {
                    setDeleteCardWarningOpen(false);
                    dispatch(removePaymentMethod(deletedCardId));
                }}
                onCloseButtonClick={() => {setDeleteCardWarningOpen(false)}} />
            <DataList
                title={t('Billing.WaterBillingAccount', {count: navilineListData.length})}
                items={navilineListData}
                addCallback={() => dispatch(setNavilineModalOpen(true))}
                deleteCallback={(_id, data) => {
                    dispatch(setDeleteModalOpen(true))
                    dispatch(setAutopayCustomerId(data.CustomerId))
                    dispatch(setAutopayLocationId(data.LocationId))
                }}
                tagClickCallback={(_id, data) => {
                    setAutopayAcctEnabled(data.Autopay?.AutopayEnabled);
                    setAutopayAcctCard(data.Autopay?.PaymentProfileId);
                    setAutopayId(data.Autopay?.AutopayId);
                    dispatch(setAutopayAccount(_id));
                    dispatch(setAutopayCustomerId(data.CustomerId));
                    dispatch(setAutopayLocationId(data.LocationId));
                    dispatch(setAutopayModalOpen(true));
                }}
            />

            <DataList
                title={t('Billing.StoredPaymentMethod', {count: paymentMethods.length})}
                items={paymentMethods}
                addCallback={() => dispatch(setAuthNetModalOpen(true))}
                deleteCallback={(_id, data) => removeCard(_id)}
            />

        </div>
    );
};
