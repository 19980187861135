import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Modal, Radio } from 'semantic-ui-react';
import { Button } from '../../../../components/button/Button';
import { Checkbox } from '../../../../components/checkbox/Checkbox';
import { Icon } from '../../../../components/icon/Icon';
import { axios } from '../../../../services/axiosService';
import { loadPaymentInformation, loadPaymentProfile } from '../billingSettingsActionCreators';
import { setAutopayAccount, setAutopayCustomerId, setAutopayLocationId, setAutopayModalOpen, useAppStateBillingSettingsSelector } from '../billingSettingsSlice';
import styles from './AutopayConfigModal.module.scss';
import { openMessageModal } from '../../../../components/statusModal/messageModalActionCreators';
import { AnyAction } from 'redux';

interface AutopayConfigModalProps {
    modalOpen: boolean;
    enabled?: boolean;
    savedCard?: string;
    id?: string;
}

export const AutopayConfigModal = ({ modalOpen, enabled: propEnabled, savedCard: propSavedCard, id: propId }: AutopayConfigModalProps) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const [enabled, setEnabled] = useState(propEnabled ?? false);
    const [apiError, setApiError] = useState('');
    const [savedCard, setSavedCard] = useState(propSavedCard ?? undefined);
    const [id, setId] = useState<string | undefined>();

    const { paymentProfile, autopayLocationId, autopayCustomerId } = useAppStateBillingSettingsSelector(s => s);

    useEffect(() => {
        setEnabled(propEnabled ?? false);
        setSavedCard(propSavedCard ?? undefined);
        setId(propId);
    }, [propEnabled, propSavedCard, propId]);

    const onClose = () => {
        setApiError('');
        dispatch(setAutopayAccount(null));
        dispatch(setAutopayModalOpen(false));
    };

    const onSubmit = async () => {
        if (!savedCard && !paymentProfile.length) {
            setApiError(t('Billing.ConfigureAutopayNoCardsError'));
            return
        } else if (!savedCard && paymentProfile.length) {
            setApiError(t('Billing.ConfigureAutopaySelectCardError'));
            return;
        }

        try {
            if (enabled) {
                await axios.put('/autopay', {
                    navilineCustomerId: autopayCustomerId,
                    navilineLocationId: autopayLocationId,
                    savedCard: savedCard,
                });
                dispatch(openMessageModal('success-update', 'success') as unknown as AnyAction);
            } else {
                await axios.delete(`/autopay/${id}`);
                dispatch(openMessageModal('success-update', 'success') as unknown as AnyAction);
            }

            dispatch(loadPaymentInformation());
            dispatch(loadPaymentProfile());
            dispatch(setAutopayModalOpen(false));
            dispatch(setAutopayAccount(null));
            dispatch(setAutopayCustomerId(null));
            dispatch(setAutopayLocationId(null));
        } catch(err) {
            console.error(err);
            dispatch(openMessageModal('error-update', 'error') as unknown as AnyAction);
            setApiError(t('Billing.AutopaySaveError'));
        }
        return;
    };

    return (
        <Modal open={modalOpen} onClose={onClose} size="small">
            <Modal.Header>
                {t('Billing.ConfigureAutopay')}
                <div style={{ 'float': 'right' }}>
                    <Icon
                        icon='x'
                        color='black'
                        size="large"
                        onClick={onClose}
                    />
                </div>
            </Modal.Header>
            <Modal.Content>
                <div className={styles.modalContent}>
                    {(apiError !== '') &&
                        <p className={styles.errorMessage}>{apiError}</p>
                    }
                    <div>
                        <Checkbox value={enabled} onChange={(_) => setEnabled(!enabled)} text={t('Billing.EnableAutopay')} />
                    </div>
                    {enabled && paymentProfile.length ?
                        <div className={styles.formContainer}>
                            <div>
                                {t('Billing.SelectCard')}:
                            </div>
                            <div>
                                {
                                    paymentProfile.map((x: any) => (
                                        <Radio
                                            key={x.Id}
                                            label={`${x.Type} - ${x.MaskedAccountNumber}`}
                                            name='autopayCard'
                                            value={x.Id}
                                            checked={savedCard === x.Id}
                                            onChange={() => setSavedCard(x.Id)}
                                        />
                                    ))
                                }
                            </div>
                        </div>
                    : enabled && !paymentProfile.length ? 
                        <>
                            <div className={styles.errorMessage}>
                                {t('Billing.NoSavedCards')}
                            </div>
                        </>
                    : null}
                    <div className={styles.buttonWrapper}>
                        <Button onClick={() => onSubmit()}>Save</Button>
                    </div>
                </div>
            </Modal.Content>
        </Modal>
    )
}