import { AnyAction, Dispatch } from 'redux';
import { axios } from '../../../services/axiosService';
import { NavilineAccountInformation, setCustomerPaymentProfile, setNavilineAccountNumber, setNavilineListData, setNavilineModalOpen, setStreetNumber } from './billingSettingsSlice';
import { v4 as uuid } from 'uuid';
import { AppState } from '../../../store/appState';
import { openMessageModal } from '../../../components/statusModal/messageModalActionCreators';
import { setLoading } from '../../userAdminView/list/UserSearchV2Slice';

export const loadPaymentInformation = () => async (dispatch: Dispatch, getState: () => AppState) => {
    try {
        const {billingSettings: {userId, organizationId}} = getState();
        const queryParam = organizationId ? `organizationId=${organizationId}` : `userId=${userId}`;
        const results = (await axios.get(`/naviline/paymentInformation?${queryParam}`)).data as NavilineAccountInformation[];
        const listData = results.map(info => {
            let autoPayStatus = "";
            switch(info.Autopay?.AutopayStatus) {
                case "S":
                    autoPayStatus += "ACH";
                    break;
                case "C":
                    autoPayStatus += "ACH";
                    break;
                case "R":
                    autoPayStatus += "Credit Card";
                    break;
                default:
                    autoPayStatus += "UNENROLLED";
                    break;
            }

            return {
                Id: uuid(),
                Label: `${info.CustomerId}-${info.LocationId} Current Autopay Status: ${autoPayStatus}`,
                Tag: 'Manage Autopay',
                Data: info
            };
        });
        dispatch(setNavilineListData(listData));
    } catch (err) {
        console.error(err);
        dispatch(openMessageModal('error-add', 'error') as unknown as AnyAction);
    }

    return;
}

export const loadPaymentProfile = (userId?: string) => async (dispatch: Dispatch, getState: () => AppState) => {
    try {
        let url = '/payment-methods';
        if (userId) {
            url += `?userId=${userId}`;
        }
        const result = (await axios.get(url)).data;
        dispatch(setCustomerPaymentProfile(result));
    } catch (err) {
        console.error(err);
        dispatch(openMessageModal(null, 'error') as unknown as AnyAction);
    }

    return;
}

export const addPaymentInformation = (accountNumber: string, 
    streetNumber: string, 
    navilineListData: {
        Id: string;
        Label: string;
        Data: NavilineAccountInformation;
    }[]) => async (dispatch: Dispatch, getState: () => AppState) => {
        try {
            dispatch(setNavilineModalOpen(false));
            const {billingSettings: {userId, organizationId}} = getState();
            const [customerId, locationId] = accountNumber.split('-');

            // Check if the account number matches any other Naviline account numbers
            for (const account of navilineListData) {
                const locationId = account.Data.LocationId;
                const customerId = account.Data.CustomerId;
                const existingAccountNumber = customerId + "-" + locationId;
                console.log(existingAccountNumber);
                if (existingAccountNumber === accountNumber) {
                    dispatch(setNavilineAccountNumber(""));
                    dispatch(setStreetNumber(""));
                    dispatch(openMessageModal('duplicate-payment-account', 'error') as unknown as AnyAction);
                    return;
                }
            }
            
            const result = (await axios.post(`/naviline/paymentInformation`, {userId, organizationId, customerId, locationId, streetNumber})).data;
            if (result.success) {
                dispatch(setNavilineAccountNumber(""));
                dispatch(setStreetNumber(""));
                loadPaymentInformation()(dispatch, getState);
                dispatch(openMessageModal('success-add', 'success') as unknown as AnyAction);
            } else {
                dispatch(openMessageModal('error-add', 'error') as unknown as AnyAction);
            }
        } catch (err) {
            console.error(err);
            dispatch(openMessageModal('error-add', 'error') as unknown as AnyAction);
        }
        return;
}

export const removePaymentInformation = (customerId: string, locationId: string) => async (dispatch: Dispatch, getState: () => AppState) => {
    try {
        const {billingSettings: {userId, organizationId}} = getState();
        const queryParam = organizationId ? `organizationId=${organizationId}` : `userId=${userId}`;
        const result = (await axios.delete(`/naviline/paymentInformation?${queryParam}&customerId=${customerId}&locationId=${locationId}`)).data;
        console.log(result);
        loadPaymentInformation()(dispatch, getState);
        dispatch(openMessageModal('success-remove', 'success') as unknown as AnyAction);
    } catch (err) {
        console.error(err);
        dispatch(openMessageModal('error-remove', 'error') as unknown as AnyAction);
    }
    return;
}

export const removePaymentMethod = (paymentProfileId: string) => async (dispatch: Dispatch, getState: () => AppState) => {
    try {
        const result = (await axios.delete(`/payment-methods/${paymentProfileId}`)).data;
        console.log(result);
        loadPaymentProfile()(dispatch, getState);
        loadPaymentInformation()(dispatch, getState);
        dispatch(openMessageModal('success-remove', 'success') as unknown as AnyAction);
    } catch (err) {
        console.error(err);
        dispatch(openMessageModal('error-remove', 'error') as unknown as AnyAction);
    }
    return;
}

export const removePaymentMethodAdmin = (paymentProfileId: string, userId: string) => async (dispatch: Dispatch, getState: () => AppState) => {
    try {
        dispatch(setLoading(true));
        await axios.delete(`/payment-methods/${paymentProfileId}/${userId}`);
        loadPaymentProfile(userId)(dispatch, getState);
        loadPaymentInformation()(dispatch, getState);
        dispatch(openMessageModal('success-remove', 'success') as unknown as AnyAction);
    } catch (err) {
        console.error(err);
        dispatch(openMessageModal('error-remove', 'error') as unknown as AnyAction);
    }
    dispatch(setLoading(false));
    return;
}
