import React from 'react';
import Moment from 'moment';
import styles from './billPayAccountSelection.module.scss';
import { Modal } from 'semantic-ui-react';
import { NavilinePaymentHistory, NavilinePaymentHistoryV2 } from '../../models/navilinePaymentHistory';
import { Icon } from '../icon/Icon';
import { useTranslation } from 'react-i18next';

interface BillPayAccountSelectionProps {
    modalOpen: boolean;
    onClose: () => void;
    onSelect: (customerId: string, locationId: string, accountType: string, accountNumber: string, amount: number) => void;
    paymentHistory: NavilinePaymentHistory[] | null | undefined;
    paymentHistoryV2: NavilinePaymentHistoryV2 | null | undefined;
}

export const BillPayAccountSelection = ({ modalOpen, onClose, onSelect, paymentHistory, paymentHistoryV2 }: BillPayAccountSelectionProps) => {
    const { t } = useTranslation();

    return (
        <Modal open={modalOpen} onClose={() => onClose()} size="small">
            <Modal.Header>
                {t('Billing.AccountSelection')}
                <div style={{ 'float': 'right' }}>
                    <Icon
                        icon='x'
                        color='black'
                        size="large"
                        onClick={onClose}
                    />
                </div>
            </Modal.Header>
            <Modal.Content>
                <ul className={styles.leaders}>
                    {((paymentHistoryV2 && paymentHistoryV2.navilineAccountInfo) ? paymentHistoryV2.navilineAccountInfo : paymentHistory)?.map(accountHistory => {
                        return (
                            <li
                                key={`${accountHistory.accountInfo.customerId}-${accountHistory.accountInfo.locationId}`}
                                className={styles.billPayDetails}
                            >
                                {accountHistory.upcomingBills.length ?
                                accountHistory.upcomingBills.map(({ amount, type, dueDate }, index) => {
                                    const numericAmount = parseFloat(amount as unknown as string)
                                    const amountSign = numericAmount < 0 ? '-' : '';
                                    const unsignedAmount = Math.abs(numericAmount).toFixed(2);
                                    const formattedAmount = `${amountSign}$${unsignedAmount}`;

                                    return (
                                        <div
                                            key={index}
                                            onClick={() => onSelect(accountHistory.accountInfo.customerId, accountHistory.accountInfo.locationId, 'UTILITY', `${accountHistory.accountInfo.customerId}-${accountHistory.accountInfo.locationId}`, numericAmount ?? 0)}>
                                            <span>{type}: {formattedAmount}</span>
                                            <span>{Moment(dueDate).format('MM/DD/YYYY')}</span>
                                        </div>
                                    )
                                })
                                :
                                    <div
                                        onClick={() => onSelect(accountHistory.accountInfo.customerId, accountHistory.accountInfo.locationId, accountHistory.accountInfo.type, `${accountHistory.accountInfo.customerId}-${accountHistory.accountInfo.locationId}`, 0)}>
                                        <span>{accountHistory.accountInfo.type}: $0.00</span>
                                    </div>
                                }
                            </li>
                        )
                    })}
                </ul>
            </Modal.Content>
        </Modal>
    )
}
